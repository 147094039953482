export const LoadingSpinner = () => {
  return (
    <div className="divLoader">
      <object
        type="image/svg"
        className="svgLoader"
        data="favicon-light.svg"
        aria-label="loading..."
      ></object>
    </div>
  );
};

export default LoadingSpinner;
